import { Trans, useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';

type ConfirmDisableAutoIncreaseDialogProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmDisableAutoIncreaseDialog({
  open,
  isLoading,
  onClose,
  onConfirm
}: ConfirmDisableAutoIncreaseDialogProps) {
  const [t] = useTranslation();

  const dialogContent = () => (
    <div aria-describedby="confirm-disable-autoincrease-dialog">
      <p>{t('components.billing.addons.transactions.auto_increase.content')}</p>
      <ul className="mt-2 list-inside list-disc space-y-2 pl-5">
        <li key="item1">
          <Trans i18nKey="components.billing.addons.transactions.auto_increase.content-consequence-1">
            extra transactions will <strong>not</strong> be purchased; and
          </Trans>
        </li>
        <li key="item2">
          {t('components.billing.addons.transactions.auto_increase.content-consequence-2')}
        </li>
      </ul>
    </div>
  );

  const dialogFooter = () => (
    <Dialog.Footer intent="borderless">
      <Dialog.Close asChild>
        <Button intent="minimal" onClick={() => onClose()}>
          {t('components.billing.addons.transactions.auto_increase.actions.keep')}
        </Button>
      </Dialog.Close>
      <Button type="submit" intent="destructive" isLoading={isLoading} onClick={onConfirm}>
        {t('components.billing.addons.transactions.auto_increase.actions.turn_off')}
      </Button>
    </Dialog.Footer>
  );

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DashboardDialogContent
        headerTitle={t('components.billing.addons.transactions.auto_increase.title')}
        headerDescription=""
        footer={dialogFooter()}
        onCloseAutoFocus={onClose}
      >
        {dialogContent()}
      </DashboardDialogContent>
    </Dialog>
  );
}
