import { useContext, useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  defaultAppTheme,
  ThemeProvider,
  ThemeProviderContext,
  Toaster,
  Tooltip
} from '@knack/asterisk-react';
import { Elements as StripeElementsProvider } from '@stripe/react-stripe-js';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { ChecklistsProvider } from '@/contexts/ChecklistsContext';
import { PostSignUpProvider } from '@/contexts/PostSignUpContext';
import { getStripeObject, getStripeOptions } from '@/utils/stripe';
import { ErrorBoundary } from '@/components/errors/ErrorBoundary';
import { ErrorFallback } from '@/components/errors/ErrorFallback';
import { PostUpdatePlanProvider } from './contexts/PostUpdatePlanContext';
import { IntercomProvider } from './IntercomProvider';

if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
  LogRocket.init(import.meta.env.PUBLIC_LOGROCKET_APP_ID, {
    rootHostname: import.meta.env.PUBLIC_LOGROCKET_ROOT_HOSTNAME ?? 'knack.com',
    dom: {
      baseHref: `${window.location.protocol}//${window.location.hostname}/`
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body?.includes('"password":')) {
          const params = JSON.parse(request.body);
          params.password = '********';
          request.body = JSON.stringify(params);
        }

        // otherwise log the request normally
        return request;
      }
    }
  });
  setupLogRocketReact(LogRocket);
}

function StripeProviderWrapper({ children }: { children: React.ReactNode }) {
  const { isDarkMode } = useContext(ThemeProviderContext);
  const stripeObject = useMemo(() => getStripeObject(), []);

  return (
    <StripeElementsProvider stripe={stripeObject} options={getStripeOptions(isDarkMode)}>
      {children}
    </StripeElementsProvider>
  );
}

export function AppProviders({ children }: { children: React.ReactNode }) {
  const theme = {
    ...defaultAppTheme,
    colors: {
      ...defaultAppTheme.colors,
      preset: 'default'
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c Knack Dashboard (${APP_VERSION})`,
      'background: #982a86; color: #ffffff; font-size: 20px; padding:5px'
    );
    // eslint-disable-next-line no-console
    console.log(`%cLast modification: ${APP_LAST_MOD}`, 'font-size: 14px;');
  }, []);

  return (
    <FlagsmithProvider
      options={{
        environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENV_ID,
        enableAnalytics: true,
        cacheFlags: true
      }}
      flagsmith={flagsmith}
    >
      <HelmetProvider>
        <ErrorBoundary fallbackUI={ErrorFallback}>
          <ThemeProvider theme={theme}>
            <PostSignUpProvider>
              <ChecklistsProvider>
                <PostUpdatePlanProvider>
                  <Toaster />
                  <Tooltip.Provider>
                    <StripeProviderWrapper>{children}</StripeProviderWrapper>
                    <IntercomProvider />
                  </Tooltip.Provider>
                </PostUpdatePlanProvider>
              </ChecklistsProvider>
            </PostSignUpProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </FlagsmithProvider>
  );
}
