/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  boot,
  hide,
  Intercom,
  onHide,
  onShow,
  show,
  shutdown,
  update
} from '@intercom/messenger-js-sdk';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import IntercomMinimizeIcon from '@/assets/svg/intercom-minimize-icon.svg';
import IntercomOpenIcon from '@/assets/svg/intercom-open-icon.svg';
import { dateCreatedFromMongoId } from './utils/dateCreatedFromMongoId';
import { isFlagEnabled } from './utils/flagsmith';

export function shouldInstallIntercom() {
  return import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
}

export function IntercomProvider() {
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [isOpen, setIsOpen] = useState(false); // Track dragging state
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const intercomRef = useRef(null);
  const { data: session } = useSessionQuery();
  const { data: account } = useAccountQuery();
  const isAuthenticated = !!session?.user.id;
  const { pathname } = useLocation();
  const isNextGenApp = isFlagEnabled('full_nextgen_access') || isFlagEnabled('only_nextgen_access');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [t] = useTranslation();

  const isIntercomActive = useMemo(
    () => shouldInstallIntercom() && isAuthenticated,
    [account, isAuthenticated]
  );

  const getInitParams = useCallback(() => {
    if (!account) return null;

    // Default value in case something is undefined
    let signedUpAt = new Date(Date.now());

    // shared builders don't have an account id
    if (account?.id) {
      signedUpAt = dateCreatedFromMongoId(account.id);
    } else if (session?.user?.id) {
      signedUpAt = dateCreatedFromMongoId(session.user.id);
    }

    return {
      name: `${account.session.user.first_name ?? ''} ${account.session.user?.last_name ?? ''}`.trim(),
      email: account.session.user.email,
      user_id: account.session.user.id,
      account: account.slug,
      plan_name: account.product_plan?.name,
      builder_version: isNextGenApp ? '4' : '3',
      signed_up_at: signedUpAt
    };
  }, [account]);

  // This is meant to be called on every render as detailed by the package documentation:
  // > The package keeps track of any instances needed internally, therefore re-renders due
  // > to DOM changes won't trigger re-boot of the messenger.
  // See https://www.npmjs.com/package/@intercom/messenger-js-sdk
  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      return;
    }

    Intercom({ app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID });
  });

  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      shutdown();

      return;
    }

    boot({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID,
      hide_default_launcher: true,
      custom_launcher_selector: '#grab'
    });
    update({ ...params });
    onHide(() => setIsOpen(false));
    onShow(() => setIsOpen(true));
  }, [getInitParams, isIntercomActive, isAuthenticated, pathname, session]);

  const handleClick = (e) => {
    if (isDragging) {
      e.stopPropagation(); // Stop click event if dragging
      setIsDragging(false); // Reset dragging state
    } else if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  useEffect(() => {
    const savedPosition = localStorage.getItem('intercom-launcher-position');
    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    }
  }, []);

  return isIntercomActive && position ? (
    <Draggable
      key="grab"
      bounds="parent"
      defaultPosition={position}
      onStart={(e, data) => setDragStart({ x: data.x, y: data.y })}
      onDrag={(e, data) => {
        const distance = Math.sqrt((data.x - dragStart.x) ** 2 + (data.y - dragStart.y) ** 2);

        // Prevent accidental drag detection for small mouse movements (< 5px)
        if (distance > 1) {
          setIsDragging(true);
        }
      }}
      onStop={(e, data) => {
        const { x, y } = data;
        setPosition({ x, y });
        localStorage.setItem('intercom-launcher-position', JSON.stringify({ x, y }));
        setTimeout(() => setIsDragging(false), 100);
      }} // Short delay to prevent click event
    >
      <div
        className="fixed bottom-[50px] right-[50px] z-[99999] h-[50px] w-[50px] cursor-grab"
        onClick={handleClick}
      >
        <div
          ref={intercomRef}
          className="intercom-launcher absolute bottom-0 top-0 flex w-full cursor-pointer items-center justify-center rounded-full bg-brand p-[5px] transition-transform duration-150 ease-linear"
          role="button"
          data-testid="launcher-with-badge-cutout-none"
          aria-label={t('intercom.open_launcher')}
          tabIndex={0}
        >
          {!isOpen ? (
            <div data-testid="launcher-default-open-icon">
              <img
                src={IntercomOpenIcon}
                alt={t('intercom.open_icon')}
                className="pointer-events-none"
              />
            </div>
          ) : (
            <div data-testid="launcher-minimize-icon">
              <img
                src={IntercomMinimizeIcon}
                alt={t('intercom.minimize_icon')}
                className="pointer-events-none"
              />
            </div>
          )}
        </div>
      </div>
    </Draggable>
  ) : null;
}
